"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Security_1 = require("../../../Security");
exports.default = (function (url) {
    if ('/logout' == window.location.pathname || '/logout/' == window.location.pathname) {
        return url;
    }
    if ('/' !== url.charAt(url.length - 1)) {
        url = "".concat(url, "/");
    }
    return "".concat(url, "?").concat(Security_1.windowKeys.REDIRECT_RESPONSE, "=").concat(encodeURIComponent(window.location.href));
});
