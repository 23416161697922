"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var specialChars = ['!', '"', '@', '$', '%', '&', '(', ')', '=', '?'];
var getSpecialChar = function () { return specialChars[Math.floor(Math.random() * specialChars.length)]; };
exports.default = (function () {
    var x1 = Math.random().toString(24).slice(2);
    var x2 = getSpecialChar() + getSpecialChar() + getSpecialChar() + getSpecialChar() + getSpecialChar();
    var x3 = Math.random().toString(36).toUpperCase().slice(2);
    var x1_chars = x1.split('');
    var x2_chars = x2.split('');
    var x3_chars = x3.split('');
    var all = [];
    for (var x = 0; x < 20; x++) {
        if (undefined !== x1_chars[x]) {
            all.push(x1_chars[x]);
        }
        if (undefined !== x2_chars[x]) {
            all.push(x2_chars[x]);
        }
        if (undefined !== x3_chars[x]) {
            all.push(x3_chars[x]);
        }
    }
    return all.join('');
});
