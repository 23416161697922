/**
 * Stymulus controller
 */
import "regenerator-runtime/runtime";
import { Controller } from 'stimulus';
import { createRoot } from 'react-dom/client';
import React from 'react';
import App from '../../react/Frontend/index';
import isNotEmptyString from "../../react/Common/Functions/Types/isNotEmptyString";
import { decrypt } from "../../Cryption/cryption";

export default class extends Controller {
    static targets = ['loading_placeholder'];

    static values = {
        food: String,
        recipe: String,
        exercise: String,
        dietTemplate: String,
        trainingTemplate: String,
        user: String,
        diet: String,
        dietDay: String,
        urlLoginGoogle: String,
        urlLoginFacebook: String,
        cookies: Array,
        authorImages: Object,
        onlineCurrency: Object,
    };

    getDomReference(name) {
        try {
            return undefined !== this[`${name}Target`] ? this[`${name}Target`] : null;
        } catch {
            return null;
        }
    }

    connect() {
        if (null !== this.getDomReference('loading_placeholder')) {
            this.getDomReference('loading_placeholder').style.display = 'none';
        }
        /**
         * React v8 support 
         * - first get DOM node
         * - second create root element where the node will be inserted by react library
         * - third render it by react library
         */
        const rootElement = document.getElementById('frontend');

        if (null !== rootElement) {
            const root = createRoot(rootElement);

            root.render(
                <App
                    CURRENT_FOOD={this.getItem(this.foodValue)}
                    CURRENT_RECIPE={this.getItem(this.recipeValue)}
                    CURRENT_EXERCISE={this.getItem(this.exerciseValue)}
                    CURRENT_DIET_TEMPLATE={this.getItem(this.dietTemplateValue)}
                    CURRENT_TRAINING_TEMPLATE={this.getItem(this.trainingTemplateValue)}
                    CURRENT_USER={this.getItem(this.userValue)}
                    CURRENT_DIET={this.getItem(this.dietValue)}
                    CURRENT_DIET_DAY={this.getItem(this.dietDayValue)}
                    URL_LOGIN_GOOGLE={this.urlLoginGoogleValue}
                    URL_LOGIN_FACEBOOK={this.urlLoginFacebookValue}
                    available_cookies={this.cookiesValue}
                    authorImages={this.authorImagesValue}
                    onlineCurrency={this.onlineCurrencyValue}
                />
            );
        }
    };

    getItem(data) {
        let item = data;
        if (isNotEmptyString(data)) {
            try {
                item = decrypt(item);
                item = JSON.parse(item);
            } catch (e) { }
        } else {
            item = {};
        }

        return item;
    }
};