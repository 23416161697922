"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (password) {
    var poorRegExp = /[a-z]/;
    var weakRegExp = /(?=.*?[0-9])/;
    ;
    var strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    var whitespaceRegExp = /^$|\s+/;
    var poorPassword = password.match(poorRegExp);
    var weakPassword = password.match(weakRegExp);
    var strongPassword = password.match(strongRegExp);
    var whitespace = password.match(whitespaceRegExp);
    if (password.length >= 10 && (poorPassword && weakPassword) && strongPassword) {
        return 100;
    }
    if (password.length >= 9 && (poorPassword && weakPassword) && strongPassword) {
        return 90;
    }
    if (password.length >= 8 && (poorPassword && weakPassword) && strongPassword) {
        return 80;
    }
    if (password.length >= 7 && (poorPassword && weakPassword) && strongPassword) {
        return 70;
    }
    if (password.length >= 6 && (poorPassword && weakPassword) && strongPassword) {
        return 60;
    }
    if (password.length >= 5 && poorPassword && (weakPassword || strongPassword)) {
        return 50;
    }
    if (password.length >= 4 && poorPassword && (weakPassword || strongPassword)) {
        return 40;
    }
    if (password.trim().length == 0) {
        return 0;
    }
    if (password.length >= 3 && (poorPassword || weakPassword || strongPassword)) {
        return 30;
    }
    if (password.length >= 2 && (poorPassword || weakPassword || strongPassword)) {
        return 20;
    }
    if (password.length >= 1 && (poorPassword || weakPassword || strongPassword)) {
        return 10;
    }
    return 0;
});
