import "regenerator-runtime/runtime";
import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['content'];

    connect() {
        this.hideAll();
    }

    toggle(event) {
        const target = event.currentTarget;
        const content = target.nextElementSibling;

        if (content) {
            const isHidden = content.style.display === 'none';

            this.hideAll();

            if (isHidden) {
                this.show(content);
            }
        }
    }

    hideAll() {
        this.contentTargets.forEach(content => {
            this.hide(content);
        });
    }

    show(content) {
        content.style.display = 'block';
    }

    hide(content) {
        content.style.display = 'none';
    }
}