"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CopyObject = function (object) {
    var newObject = {};
    if (typeof {} !== typeof object || !object) {
        return newObject;
    }
    var keys = [];
    try {
        keys = Object.keys(object);
    }
    catch (e) {
        return newObject;
    }
    for (var x = 0; x <= keys.length - 1; x++) {
        newObject[keys[x]] = object[keys[x]];
    }
    return newObject;
};
exports.default = CopyObject;
