"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        translation: 'Deutschland',
        code: 'DE',
        phonePrefix: '(+49)',
    },
    {
        translation: 'Polska',
        code: 'PL',
        phonePrefix: '(+48)',
    },
];
