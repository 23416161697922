"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepStates = void 0;
var StepStates;
(function (StepStates) {
    StepStates["NOT_STARTED"] = "not_started";
    StepStates["CURRENT"] = "current";
    StepStates["ERROR"] = "error";
    StepStates["COMPLETED"] = "completed";
})(StepStates = exports.StepStates || (exports.StepStates = {}));
