"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.basketAllItemsPrice = exports.basketSingleItemPrice = void 0;
;
var basketSingleItemPrice = function (tax, item, payment_currency) {
    if (payment_currency === void 0) { payment_currency = ''; }
    var quantity = item.quantity, price = item.price, discount_percentage = item.discount_percentage;
    var priceQuantity = quantity * price;
    var totalNoTax = priceQuantity;
    var _discount = (priceQuantity * (discount_percentage / 100));
    var totalMinusDiscount = totalNoTax - _discount;
    var _tax = (totalMinusDiscount * (tax / 100));
    var totalWithTax = totalMinusDiscount + _tax;
    return {
        price: price,
        quantity: quantity,
        priceQuantity: priceQuantity,
        totalNoTax: totalNoTax,
        totalMinusDiscount: totalMinusDiscount,
        discount: _discount,
        tax: _tax,
        totalWithTax: totalWithTax,
        ui: {
            totalNoTax: "".concat(totalNoTax.toFixed(2), " ").concat(payment_currency),
            totalMinusDiscount: "".concat(totalMinusDiscount.toFixed(2), " ").concat(payment_currency),
            discount: "".concat(_discount.toFixed(2), " ").concat(payment_currency),
            tax: "".concat(_tax.toFixed(2), " ").concat(payment_currency),
            totalWithTax: "".concat(totalWithTax.toFixed(2), " ").concat(payment_currency),
            price: "".concat(price.toFixed(2), " ").concat(payment_currency),
            priceQuantity: "".concat(priceQuantity.toFixed(2), " ").concat(payment_currency),
            quantity: "".concat(quantity),
        }
    };
};
exports.basketSingleItemPrice = basketSingleItemPrice;
var basketAllItemsPrice = function (tax, items) {
    var totalNoTax = 0;
    var _discount = 0;
    var payment_currency = '';
    items.map(function (item) {
        var price = item.price, discount_percentage = item.discount_percentage, payment_currency_short = item.payment_currency_short;
        var cal = (0, exports.basketSingleItemPrice)(tax, item);
        totalNoTax += cal.totalNoTax;
        _discount += cal.discount;
        payment_currency = payment_currency_short || '';
    });
    var totalMinusDiscount = totalNoTax - _discount;
    var _tax = (totalMinusDiscount * (tax / 100));
    var totalWithTax = totalMinusDiscount + _tax;
    return {
        totalNoTax: totalNoTax,
        totalMinusDiscount: totalMinusDiscount,
        tax: _tax,
        totalWithTax: totalWithTax,
        payment_currency: payment_currency,
        discount: _discount,
        ui: {
            totalNoTax: "".concat(totalNoTax.toFixed(2), " ").concat(payment_currency),
            discount: "".concat(_discount.toFixed(2), " ").concat(payment_currency),
            totalWithTax: "".concat(totalWithTax.toFixed(2), " ").concat(payment_currency),
            tax: "".concat(_tax.toFixed(2), " ").concat(payment_currency),
        }
    };
};
exports.basketAllItemsPrice = basketAllItemsPrice;
